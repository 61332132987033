<template>
    <div class="submissions-table-wrapper">

        <!-- Submissions Type -->
        <h1>Latest Submissions</h1>

        <!-- Submissions Table -->
        <div :class="{'submissions-table': true, 'loading': !loaded}" ref="submissionsTable">

            <table class="table">
                <thead>
                <tr>
                    <th>Submission</th>
                    <th>Form</th>
                    <th>Data</th>
                    <th>Submitted On</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(submission) in items" :key="submission.hashId" :class="{ 'new': submission.status !== 'seen', 'spam': submission.spam }">
                    <td>
                        <router-link :to="'/submissions/' + submission.hashId" class="submission-name" :title="submission.name">
                            <span :class="{'submission-avatar': true, 'text-light': submission.avatar.text === 'light', 'text-dark': submission.avatar.text === 'dark'}" :style="{backgroundColor: submission.avatar.color}">{{ submission.avatar.credentials }}</span>
                            <strong>{{ submission.name }}</strong>
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/forms/' + submission.form.hashId" class="submission-form">
                            <span class="form-color" :style="{'background-color': submission.form.color.color}"></span>
                            <strong class="form-name">{{ submission.form.name }}</strong>
                        </router-link>
                    </td>
                    <td class="submission-inline-fields-column">
                        <div class="submission-inline-fields">
                            <div class="fields-toggle">{{ Object.keys(submission.fields || {}).length }} fields</div>
                            <div class="fields-popup">
                                <div class="submission-fields">
                                    <h3>
                                        <img src="@/assets/icons/forms.svg" alt="Fields">
                                        <span>Submission Details</span>
                                    </h3>
                                    <div class="fields-wrapper" v-if="submission.fields">
                                        <div class="field-item" v-for="(value, field) in submission.fields" :key="field">
                                            <label>{{ prepareFieldName(field) }}:</label>
                                            <div class="form-control" contenteditable="true">
                                                {{ submission.fields[field] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-fields" v-if="submission.fields === null || Object.keys(submission.fields || {}).length === 0">
                                        This submission doesn't contain any fields.
                                    </div>
                                </div>
                            </div>
                            <span class="field-spam" v-if="submission.spam">Spam</span>
                        </div>
                    </td>
                    <td class="submission-date">
                        {{ readableDate(submission.created_at) }}
                    </td>
                </tr>

                <!-- Loading Effect -->
                <tr v-if="!loaded" class="loading-tr">
                    <td>Loading effect entry</td>
                    <td>Loading effect</td>
                    <td>Loading</td>
                </tr>

                <!-- No Submissions -->
                <tr class="no-submissions" v-if="loaded && items.length === 0">
                    <td colspan="4" class="py-5 text-center">You will view your submissions here upon receiving at least one submission through any of your web forms.</td>
                </tr>
                <tr v-if="loaded && items.length >= 20">
                    <td colspan="4" class="text-center">
                        <router-link v-if="loaded" to="/submissions" class="btn btn-primary">View all submissions ></router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {readableDate} from "@/helpers";
import repository from "@/repository/repository";

export default {
    name: "ProjectSubmissions",
    data() {
        return {
            items: [],
            loaded: false,
        }
    },
    created() {
        this.loadSubmissions();
    },
    methods: {
        readableDate,
        loadSubmissions() {
            this.loaded = false;
            if(!this.projectId) return;
            repository.get("/submissions")
                .then(response => {
                    this.items = [...response.data.items];
                    this.loaded = true;
                })
        },
        prepareFieldName(field) {
            if (field === null) return "N\\A";
            return field.split("-").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ");
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
    },
    watch: {
        projectId: function () {
            this.loadSubmissions();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.submissions-table {
    display: block;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: rgba($dark, 0.1) 0 1px 2px;
    position: relative;
    text-decoration: none;
    margin-bottom: 30px;
    z-index: 0;
    @extend .animated;

    @media (max-width: 1200px) {
        overflow: scroll;
    }

    @include smartphone {
        height: auto;
        width: calc(100% + 30px);
        margin: 0 -15px;
        overflow-y: hidden;
    }

    &:hover {
        box-shadow: $box-shadow-color 0 3px 6px;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 14;
        content: ' ';
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
        border-radius: $box-border-radius;
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border: 2px solid $border-darker-grey;
        border-right-color: transparent;
        content: ' ';
        z-index: 15;
        border-radius: 50%;
        margin: auto;
        animation: spinner-border 0.75s linear infinite;
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &.loading {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            transition: none;
        }
    }

    &:not(.loading) {
        .table {
            tbody {
                tr.loading-tr {
                    background: $white;
                    border-top: 1rem solid $white;
                    border-bottom: 1rem solid $white;
                    transform: none;

                    td {
                        color: transparent;
                        background: none;
                        user-select: none;
                        pointer-events: none;
                        transform: translateX(1.5rem);
                        height: 0.5rem;
                        padding: 0.5rem;
                        position: relative;

                        &:after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: red;
                            content: ' ';
                            z-index: 1;
                            background: rgba($background-grey, 0.5);
                            border-radius: $box-border-radius;
                        }

                        &:nth-child(2) {
                            transform: translateX(2.5rem);
                        }

                        &:nth-child(3) {
                            transform: translateX(3.5rem);
                        }
                    }
                }
            }
        }
    }

    .table {
        min-width: fit-content;

        thead {
            position: sticky;
            top: 0;
            border-top: none;
            left: 0;
            z-index: 2;
            box-shadow: rgba($dark, 0.05) 0 0.15rem 0.25rem;

            tr {
                border-top: none;
                border-bottom: none;

                th {
                    border-top: none;
                    border-bottom: none;
                    font-size: 1rem;
                    font-weight: 600;
                    padding: 1rem;
                    width: 200px;
                    white-space: nowrap;
                    background: $white;

                    &:first-child {
                        padding-left: 1.5rem;
                        border-radius: $box-border-radius 0 0 0;

                        @include smartphone {
                            padding-left: 15px;
                        }
                    }

                    &:last-child {
                        padding-right: 1.5rem;
                        border-radius: 0 $box-border-radius 0 0;

                        @include smartphone {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }

        tbody {
            border-top: none;

            tr {
                border-top: 1px solid rgba($dark, 0.05);
                @extend .animated;

                &.new {
                    background: lighten($primary, 52%);
                }

                &.spam {
                    background: $white;

                    &:hover {
                        background: none !important;
                    }

                    td {
                        opacity: 0.25;

                        &.submission-inline-fields-column {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    background: lighten($background-grey, 0%) !important;
                }

                &:last-child {
                    border-radius: 0 0 $box-border-radius $box-border-radius;

                    td {
                        &:first-child {
                            border-radius: 0 0 0 $box-border-radius;
                        }

                        &:last-child {
                            border-radius: 0 0 $box-border-radius 0;
                        }
                    }
                }

                td {
                    padding: 1rem 1rem;
                    font-size: 0.9rem;
                    color: $dark;
                    border: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                    word-wrap: break-word;
                    max-width: 250px;

                    &:first-child {
                        padding-left: 1.5rem;

                        @include smartphone {
                            padding-left: 15px;
                        }
                    }

                    &:last-child {
                        padding-right: 1.5rem;

                        @include smartphone {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }

        .submission-name {
            display: flex;
            align-items: center;
            width: 180px;
            text-decoration: none;
            color: $dark;

            &:hover {
                color: $primary;

                .submission-avatar {
                    box-shadow: rgba($primary, 1) 0 0 0 0.15rem;
                }

                strong {
                    text-decoration: underline;
                }
            }

            .submission-avatar {
                width: 2.5rem;
                height: 2.5rem;
                flex-shrink: 0;
                border-radius: 50%;
                line-height: 2.5rem;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.8rem;
                text-align: center;
                margin-right: 0.5rem;
            }

            strong {
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .submission-form {
            display: flex;
            align-items: center;

            &:hover {
                text-decoration: underline;
            }

            .form-color {
                display: block;
                width: 16px;
                height: 16px;
                line-height: 1;
                flex-shrink: 0;
                border-radius: $box-border-radius;
            }

            .form-name {
                display: block;
                font-size: 0.8rem;
                color: $dark;
                font-weight: 400;
                max-width: 12rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-left: 5px;

                @include smartphone {
                    display: none;
                }
            }
        }

        .submission-inline-fields-column {
            overflow: visible;

            .submission-inline-fields {
                position: relative;
                display: inline-flex;
                align-items: center;
                z-index: 11;

                &:hover {
                    .fields-toggle {
                        background: rgba($dark, 0.15);
                    }

                    .fields-popup {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .fields-toggle {
                    font-weight: bold;
                    font-size: 0.7rem;
                    opacity: 1;
                    color: $dark;
                    text-transform: uppercase;
                    background: rgba($dark, 0.05);
                    padding: 0.15rem 0.5rem;
                    border-radius: 0.5rem;
                    margin-left: 0.25rem;
                    cursor: pointer;
                    @extend .animated;
                }

                .field-spam {
                    font-weight: bold;
                    font-size: 0.7rem;
                    opacity: 1;
                    color: $danger;
                    text-transform: uppercase;
                    background: rgba($danger, 0.1);
                    padding: 0.15rem 0.5rem;
                    border-radius: 0.5rem;
                    margin-left: 0.25rem;
                }

                .fields-popup {
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    max-width: 25rem;
                    min-width: 20rem;
                    height: auto;
                    max-height: 25rem;
                    overflow-x: hidden;
                    background: $white;
                    border-radius: $box-border-radius;
                    box-shadow: $box-shadow-color 0 3px 6px;
                    padding: 15px;
                    z-index: 10;
                    opacity: 0;
                    visibility: hidden;
                    @extend .animated;

                    @media (max-width: 1400px) {
                        left: auto;
                        right: 100%;
                    }

                    @include smartphone {
                        display: none;
                    }

                    .submission-fields {
                        display: block;

                        h3 {
                            font-weight: 600;
                            font-size: 1rem;
                            color: $dark;
                            margin: 0 0 0.5rem 0;
                            display: flex;
                            align-content: center;

                            @include smartphone {
                                margin-bottom: 0.5rem;
                            }

                            img {
                                width: 1rem;
                                height: 1rem;
                                object-fit: contain;
                                filter: grayscale(1);
                                margin-right: 0.5rem;
                            }
                        }

                        .field-item {
                            display: block;
                            padding: 7.5px 0;
                            border-radius: $box-border-radius;
                            position: relative;
                            @extend .animated;

                            @include small-desktop {
                                flex-direction: column;
                            }

                            @include tablet {
                                flex-direction: column;
                            }

                            @include smartphone {
                                flex-direction: column;
                            }

                            &:not(:last-child):after {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                content: ' ';
                                background: $border-grey;
                            }

                            &:last-child {
                                padding-bottom: 0;
                            }

                            label {
                                margin: 0;
                                text-align: left;
                                font-weight: 600;
                                color: $dark;
                                font-size: 0.8rem;
                                user-select: none;
                                cursor: pointer;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                display: block;
                                flex-shrink: 0;
                                flex-grow: 0;

                                @include small-desktop {
                                    width: 100%;
                                    text-align: left;
                                    padding-right: 0;
                                }

                                @include tablet {
                                    width: 100%;
                                    text-align: left;
                                    padding-right: 0;
                                }

                                @include smartphone {
                                    width: 100%;
                                    text-align: left;
                                    padding-right: 0;
                                }
                            }

                            .form-control {
                                border: none;
                                box-shadow: none;
                                font-size: 0.8rem;
                                height: auto;
                                padding: 0 0 0 0;
                                max-width: 100%;
                                overflow: hidden;
                                white-space: normal;

                                @include small-desktop {
                                    padding: 5px 0;
                                }

                                @include tablet {
                                    padding: 5px 0;
                                }

                                @include smartphone {
                                    padding: 5px 0;
                                }
                            }
                        }

                        .no-fields {
                            font-size: 0.9rem;
                            color: $grey-text;
                        }
                    }
                }

            }
        }
    }
}
</style>