<template>
    <div class="form-builder-sidebar">

        <!-- Save Updates -->
<!--        <div class="form-updates">-->
<!--            <button-->
<!--                :class="{'btn btn-primary d-block w-100': true, 'disabled': status.saving}"-->
<!--                :disabled="status.saving || !status.updated"-->
<!--                @click.prevent="saveForm"-->
<!--            >-->
<!--                <span v-if="!status.saving && status.updated">Save Changes</span>-->
<!--                <span v-if="status.saving && status.updated">Saving form...</span>-->
<!--                <img src="@/assets/icons/check.svg" alt="" v-if="!status.updated" class="ml-n3">-->
<!--                <span v-if="!status.updated">Your form is up to date</span>-->
<!--            </button>-->
<!--        </div>-->
        <!-- Publish Form -->
        <div class="publish-form-widget">
            <h1>Publish form</h1>
            <p class="small mt-0 mb-0">Provide this link to collect responses on your form</p>
            <input type="text" :value="formLink" class="form-control mt-2 mb-2">
            <a :href="formLink" target="_blank" class="btn btn-primary d-block">
                <img src="@/assets/icons/external.svg" alt="">
                <span>View Form</span>
            </a>
            <div class="form-status">
                <div v-if="!status.saving && status.updated">
                    <img src="@/assets/icons/clock.svg" alt="">
                    <span>Draft changes</span>
                </div>
                <div v-if="status.saving && status.updated">
                    <div class="spinner-border"></div>
                    <span>Saving changes</span>
                </div>
                <div v-if="!status.updated">
                    <img src="@/assets/icons/check-alt.svg" alt="">
                    <span>Changes saved</span>
                </div>
            </div>
        </div>

        <!-- Widget Code -->
        <div class="publish-form-widget mt-4">
            <h1>Embed form on website</h1>
            <p class="small mt-0 mb-2">Embed this code anywhere on your website to publish your form</p>
            <CopyTextarea :content="code" button="Copy Widget Code"/>
        </div>
    </div>
</template>

<script>
import CopyTextarea from "@/components/widgets/CopyTextarea.vue";

export default {
    name: "FormWidgetSidebar",
    components: {CopyTextarea},
    props: ['secret', 'status', 'saveForm'],
    data() {
        return {
        }
    },
    computed: {
        code() {
            return '<!-- Powered by FormWise.io -->\n' +
                '<div id="formwise-form"></div>\n' +
                '<' +
                'script src="'+ process.env.VUE_APP_LANDING_URL +'/widgets/form/' + this.secret + '"><' +
                '/script>';
        },
        formLink() {
            return process.env.VUE_APP_LANDING_URL + "/forms/" + this.secret;
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-builder-sidebar {
    padding: 1.5rem 1.5rem 1.5rem 0;
    flex-shrink: 0;
    width: 25rem;
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    @extend .animated;

    &.open {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    h4 {
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        margin: 1.5rem 0 0 0;
    }

    .publish-form-widget {
        background: $white;
        padding: 1.5rem;
        border-radius: $box-border-radius;
        box-shadow: rgba($dark, 0.1) 0 1px 2px;

        .form-control {
            padding: 15px 10px;
            height: auto;
            font-size: 1rem;
            border-radius: $box-border-radius;
            border: 1px solid $border-grey;
            background: $border-grey;
            word-break: break-all;

            &:focus {
                box-shadow: none;
                border-color: $primary;
            }
        }

        .form-status {
            margin-top: 1rem;

            > div {
                display: flex;
                align-items: center;

                .spinner-border {
                    width: 0.75rem;
                    height: 0.75rem;
                    border-width: 1px;
                    margin-right: 0.5rem;
                    opacity: 0.2;
                    color: $dark;
                    flex-shrink: 0;
                }

                img {
                    width: 1rem;
                    height: 1rem;
                    filter: brightness(0);
                    opacity: 0.2;
                    flex-shrink: 0;
                    margin-right: 0.25rem;
                }

                span {
                    font-size: 0.8rem;
                    color: rgba($dark, 0.5);
                }
            }
        }
    }

    .form-updates {
        margin-bottom: 1.5rem;

        .btn {
            padding: 1.5rem;
            background: #84c318;
            border-color: #84c318;
            animation: 1.25s linear infinite blinking-effect;

            &:hover,
            &:focus {
                background: lighten(#84c318, 2.5%);
                border-color: lighten(#84c318, 2.5%);
            }

            &:active {
                background: lighten(#84c318, 5%) !important;
                border-color: lighten(#84c318, 5%) !important;
                transform: scale(0.9875);
            }
        }

        @keyframes blinking-effect {
            0% {
                box-shadow: rgba(132, 195, 24, 0.25) 0 0 0.5rem;
            }
            50% {
                box-shadow: rgba(132, 195, 24, 0.75) 0 0 1.5rem;
            }
            100% {
                box-shadow: rgba(132, 195, 24, 0.25) 0 0 0.5rem;
            }
        }

        .btn[disabled] {
            filter: grayscale(1);
            opacity: 0.5;
            animation: none;
            @extend .disable-clicks;
            @extend .disable-selection;
        }
    }

}
</style>