<template>
	<div class="form-builder">

        <!-- Desktop and above -->
        <div class="form-builder-wrapper d-none d-xl-flex">

            <!-- Builder -->
            <div class="form-builder-preview">

                <!-- Preview Box -->
                <div class="form-preview-box">

                    <div v-if="status.loaded">
                        <!-- Rows, Columns and Fields -->
                        <div class="form-preview-rows">

                            <!-- Draggable Rows -->
                            <draggable
                                v-model="fields"
                                @start="currentlyDragging=true"
                                @end="currentlyDragging=false; status.updated=true; saveForm();"
                                handle=".action-move"
                            >
                                <FormRow
                                    v-for="(row, rowKey) in fields"
                                    :row="row"
                                    :delete="deleteRow"
                                    :dragging="currentlyDragging"
                                    :id="rowKey"
                                    :key="rowKey">
                                    <FormColumn
                                        v-for="(column, columnKey) in row.columns"
                                        :column="column"
                                        :openElementSidebar="openAddElementSidebar"
                                        :rowId="rowKey"
                                        :columnId="columnKey"
                                        :currentColumn="rowKey === newElement.row && columnKey === newElement.column"
                                        :key="columnKey">
                                        <FormElement
                                            v-for="(field, fieldKey) in column.fields"
                                            :field="field"
                                            :editField="openEditElementSidebar"
                                            :rowId="rowKey"
                                            :columnId="columnKey"
                                            :fieldId="fieldKey"
                                            :currentElement="rowKey === editElement.rowId && columnKey === editElement.columnId && fieldKey === editElement.fieldId"
                                            :key="fieldKey"/>
                                    </FormColumn>
                                </FormRow>
                            </draggable>

                            <!-- Start building form message -->
                            <div class="form-preview-rows-start" v-if="!fields || fields.length === 0">
                                <img src="@/assets/icons/form-builder.svg" alt="">
                                <h4>Add Form Elements</h4>
                                <p>Start building your form by adding a row and elements</p>
                            </div>
                        </div>

                        <!-- Add New Row -->
                        <NewFormRowWidget :addNewRow="addNewRow"/>
                    </div>

                    <div v-if="!status.loaded" class="form-preview-loading">
                        <div class="spinner-border"></div>
                    </div>

                </div>
            </div>

            <!-- ElementEditors Sidebar -->
            <ElementsSidebar
                :open="newElement.open"
                :addElement="addElement"
                :closeSidebar="closeAddElementSidebar"
                ref="formBuilderElementsSidebar"
            />

            <!-- Edit Element Sidebar -->
            <EditElementSidebar
                :open="editElement.open"
                :element="editElement.element"
                :updateElement="updateElement"
                :deleteElement="deleteElement"
                :closeSidebar="closeEditElementSidebar"
            />

            <!-- Form Widget & Actions -->
            <FormWidgetSidebar
                :secret="secret"
                :status="status"
                :saveForm="saveForm"
            />
        </div>

        <!-- Mobile & Tablet Message -->
        <div class="form-builder-message d-xl-none">
            <div class="row">
                <div class="col-md-10 col-lg-6 mx-auto text-center">
                    <img src="@/assets/icons/form-builder.svg" alt="">
                    <h4>Our form builder works best on laptops and desktops</h4>
                    <p>For an easier and smoother experience, please use one of these devices to create your form</p>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import NewFormRowWidget from "@/views/Forms/FormBuilder/NewFormRowWidget.vue";
import FormRow from "@/views/Forms/FormBuilder/FormRow.vue";
import FormColumn from "@/views/Forms/FormBuilder/FormColumn.vue";
import FormElement from "@/views/Forms/FormBuilder/FormElement.vue";
import ElementsSidebar from "@/views/Forms/FormBuilder/ElementsSidebar.vue";
import draggable from 'vuedraggable'
import EditElementSidebar from "@/views/Forms/FormBuilder/EditElementSidebar.vue";
import repository from "@/repository/repository";
import FormWidgetSidebar from "@/views/Forms/FormBuilder/FormWidgetSidebar.vue";

export default {
    name: "FormBuilder",
    components: {
        FormWidgetSidebar,
        EditElementSidebar,
        FormElement,
        ElementsSidebar,
        FormColumn,
        FormRow,
        NewFormRowWidget,
        draggable,
    },
    props: [
        'projectId',
        'secret',
        'type',
    ],
    data() {
        return {
            ids: {
                row: 0,
                column: 0,
                field: 0,
            },
            status: {
                loaded: false,
                updated: false,
                saving: false,
                timeout: null,
            },
            fields: [],
            currentlyDragging: false,
            newElement: {
                open: false,
                row: null,
                column: null,
            },
            editElement: {
                open: false,
                rowId: null,
                columnId: null,
                fieldId: null,
                element: null,
            },
        }
    },
    created() {
        if(this.type !== 2) {
            this.$router.replace("/forms/" + this.secret + "/integration");
        }
        this.loadFields(this.secret);
    },
    mounted() {
        this.closeSidebarOnClick = () => {
            this.closeEditElementSidebar();
            this.closeAddElementSidebar();
        };
        document.addEventListener("click", this.closeSidebarOnClick);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeSidebarOnClick);
    },
    methods: {
        loadFields(secret) {
            this.status.loaded = false;
            repository.get("/projects/" + this.projectId + "/forms/" + secret + "/fields")
                .then(response => {
                    if(!response.data.data) {
                        this.fields = [];
                        this.ids.row = 0;
                        this.ids.column = 0;
                        this.ids.field = 0;
                    } else {
                        try {
                            let data = response.data.data;
                            this.fields = data.fields;
                            this.ids = data.ids;
                        } catch (exception) {
                            this.fields = [];
                        }
                    }
                    this.status.loaded = true;
                });
        },

        saveForm() {
            if (this.status.timeout) {
                clearTimeout(this.status.timeout);
            }
            this.status.timeout = setTimeout(() => {
                this.status.saving = true;
                repository.post("/projects/" + this.projectId + "/forms/" + this.secret + "/fields", {
                    data: JSON.stringify({
                        ids: this.ids,
                        fields: this.fields,
                    }),
                }).then(() => {
                    this.status.saving = false;
                    this.status.updated = false;
                });
            }, 2000);
        },
        
        addNewRow(columns) {
            if(columns <= 0 || columns > 3) return;
            let row = {
                _id: this.ids.row++,
                columns: [],
            };
            for (let i = 1; i <= columns; i++) {
                row.columns.push({
                    _id: this.ids.column++,
                    fields: [],
                });
            }
            this.fields.push(row);
            this.status.updated = true;
            this.saveForm();
            this.closeEditElementSidebar();
            this.closeAddElementSidebar();
        },
        deleteRow(rowId) {
            this.fields.splice(rowId, 1);
            this.status.updated = true;
            this.saveForm();
            this.closeEditElementSidebar();
            this.closeAddElementSidebar();
        },

        openAddElementSidebar(rowId, columnId) {
            this.newElement.row = rowId;
            this.newElement.column = columnId;
            this.newElement.open = true;
            this.$refs.formBuilderElementsSidebar.scrollToTop();
            this.closeEditElementSidebar();
        },
        closeAddElementSidebar() {
            this.newElement.row = null;
            this.newElement.column = null;
            this.newElement.open = false;
        },
        addElement(field) {
            field._id = this.ids.field++;
            this.fields[this.newElement.row].columns[this.newElement.column].fields.push(field);
            this.status.updated = true;
            this.saveForm();
            if(field.element !== 'hr') {
                this.openEditElementSidebar(this.newElement.row, this.newElement.column, 0);
            }
            this.closeAddElementSidebar();
        },

        openEditElementSidebar(rowId, columnId, fieldId) {
            this.editElement.rowId = rowId;
            this.editElement.columnId = columnId;
            this.editElement.fieldId = fieldId;
            this.editElement.element = this.fields[rowId].columns[columnId].fields[fieldId];
            this.editElement.open = true;
            this.closeAddElementSidebar();
        },
        closeEditElementSidebar() {
            this.editElement.rowId = null;
            this.editElement.columnId = null;
            this.editElement.fieldId = null;
            this.editElement.element = null;
            this.editElement.open = false;
        },
        updateElement(field, closeSidebar = false) {
            this.fields[this.editElement.rowId].columns[this.editElement.columnId].fields[this.editElement.fieldId] = field;
            this.status.updated = true;
            this.saveForm();
            this.$forceUpdate();
            if(closeSidebar === true) this.closeEditElementSidebar();
        },
        deleteElement() {
            this.fields[this.editElement.rowId].columns[this.editElement.columnId].fields.splice(this.editElement.fieldId, 1);
            this.status.updated = true;
            this.saveForm();
            this.closeEditElementSidebar();
            this.closeAddElementSidebar();
        },
    },
    watch: {
        secret(newFormId) {
            this.loadFields(newFormId);
        },
    },
    beforeRouteLeave (to, from , next) {
        if(this.status.updated) {
            this.saveForm();
            next()
        } else {
            next()
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-builder {
    background: $background-grey;
    flex-grow: 1;
    height: calc(100svh - 44px - 2rem);
    overflow: hidden;

    @include smartphone {
        padding: 0.75rem;
    }

    .form-builder-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        position: relative;

        .form-builder-preview {
            flex-grow: 0;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            width: calc(100% - 25rem);
            overflow-x: hidden;

            .form-preview-box {
                box-shadow: rgba($dark, 0.1) 0 1px 2px;
                padding: 2.5rem;
                background: $white;
                border-radius: $box-border-radius;
                flex-grow: 1;

                .form-preview-loading {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 2.5rem 0;
                    flex-grow: 1;
                    height: 100%;

                    .spinner-border {
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-top: 0.5rem;
                        border-width: 2px;
                        border-color: $border-darker-grey;
                        border-right-color: transparent;
                    }
                }

                .form-preview-rows {
                    padding-bottom: 0.7rem;
                }

                .form-preview-rows-start {
                    display: block;
                    text-align: center;
                    padding: 2.5rem;
                    border-radius: $box-border-radius;

                    img {
                        width: 4rem;
                        height: 4rem;
                        margin: auto;
                    }

                    h4 {
                        font-weight: 600;
                        color: $dark;
                        font-size: 1.5rem;
                        margin: 1rem 0 0.5rem 0;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .form-builder-message {
        padding: 5rem 0;

        img {
            width: 6rem;
            height: 6rem;
            filter: grayscale(1);
            opacity: 0.25;
        }

        h4 {
            font-weight: 600;
            color: $dark;
            margin: 1.5rem 0 1rem 0;
            font-size: 2rem;
        }

        p {
            margin-bottom: 0;
        }
    }
}
</style>